import { EventSignUpProps } from '../pages/events/EventSignUpStyles'

const EVENT_SIGNUP_INFO: EventSignUpProps[] = [
	// 	title: 'Build Day Sign Up',
	// 	description: 'Sign up for the outreach build days!',
	// 	src: 'https://docs.google.com/forms/d/e/1FAIpQLSdOjuVGK6zYMWIRCSSR3LGqVTssxk2A5HxDehjSpYo6C3tGAw/viewform?usp=sf_link'
	// }
]

export default EVENT_SIGNUP_INFO
